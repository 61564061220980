"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.decodeResetPasswordArgs = exports.encodeResetPasswordArgs = void 0;
exports.encodeResetPasswordArgs = function (_a) {
    var playerId = _a.playerId, token = _a.token;
    return btoa(playerId + " " + token);
};
exports.decodeResetPasswordArgs = function (base64Str) {
    var _a = atob(base64Str).split(' '), playerId = _a[0], token = _a[1];
    if (!playerId || !token) {
        return undefined;
    }
    return { playerId: playerId, token: token };
};
